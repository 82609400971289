import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Container,
  Grid,
} from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import axios from "axios";
import EventInfo from "components/eventTemplate/EventInfo";

const EventTemplate = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const [eventData, setEventData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(
          `https://api.timing-buddy.dk/api/v1/events/${eventId}`
        );
        setEventData(response.data);
      } catch (err) {
        setError("KUNNE IKKE FINDE DET GIVNE EVENT");
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
  }, [eventId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Box
        sx={{
          height: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          marginTop: 20,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 5,
        }}
      >
        <Typography variant="h1" fontSize={60} color="error">
          {error}
        </Typography>
        <SentimentVeryDissatisfiedIcon
          color="error"
          sx={{
            fontSize: 200,
            textAlign: "center",
            mt: 2,
            mb: 2,
            color: "error",
            ml: "auto",
            mr: "auto",
          }}
        />
      </Box>
    );
  }

  const markdown = eventData.description;

  return (
    <Box>
      <Box
        sx={{
          height: "70vh",
          width: "100%",
          top: "0",
          zIndex: -1,
        }}
      >
        <Box
          component="img"
          alt="trifork"
          src="/assets/trifork.jpg"
          sx={{
            height: "70vh",
            width: "100%",
            position: "absolute",
            left: 0,
            zIndex: -1,
            objectFit: "cover",
          }}
        />

        <Box
          sx={{
            position: "absolute",
            height: "70vh",
            width: "100%",
            left: 0,
            top: 0,
            zIndex: 0,
            background:
              "linear-gradient(to top, #11002F 0%, rgba(0, 0, 0, 0) 55%)",
          }}
        />
        <Box
          sx={{
            height: "40%",
            width: "80%",
            position: "relative",
            top: "35vh",
            left: "50%",
            transform: "translate(-50%, 0%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            textAlign: "left",
            boxSizing: "border-box",
          }}
        >
          <Container
            sx={{
              pb: 8,
            }}
          >
            <Grid container>
              <Grid xs={16}>
                <Typography
                  variant="h1"
                  fontSize={"30px"}
                  color={"white"}
                  sx={{
                    textShadow: "10px 10px 20px rgba(0, 0, 0, 0.7)",
                  }}
                >
                  {eventData.name}
                </Typography>
                <Typography
                  variant="h2"
                  fontSize={"20px"}
                  color={"white"}
                  sx={{
                    textShadow: "10px 10px 20px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  {new Date(eventData.start_time).toLocaleDateString("da-DK", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </Typography>

                <Typography
                  variant="h1"
                  fontSize={"16px"}
                  color={"white"}
                  sx={{
                    textShadow: "10px 10px 20px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  {eventData.location}
                </Typography>
              </Grid>
              <Grid xs={4}>
                {/* 
                <Button variant="contained" sx={{
                  top: 100,
                  left: 200,
                  pl: 6,
                  pr: 6,
                  fontSize: 20,
                }}>TILMELD</Button>
                */}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Container>
        <EventInfo
          description={eventData.description}
          eventName={eventData.name}
        />
        {/* 
        <Typography variant="h4" textAlign={"center"} sx={{ mt: 4 }}>
          PARTNERE
        </Typography>
        <Divider
          textAlign="center"
          sx={{
            mt: 4,
            mb: 4,
            color: "primary.contrastText",
            border: "solid 2px",
            width: "40%",
            mx: "auto",
          }}
        />

        <Grid container spacing={0} sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <Grid item xs={3} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
            transition: "0.2s",
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0px rgba(0, 0, 0, 0.1)',
            ":hover": {
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0px rgba(0, 0, 0, 0.3)',
              cursor: 'pointer',
            },
            p: 4,
            ml: 2,
            mr: 2,
            mb: 4,

            borderRadius: 4
          }}>
            <a
              href="https://www.sailing-aarhus.dk/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', textDecoration: 'none' }}
            >

              <Box
                component='img'
                src="/assets/sailingAarhus.png"
                sx={{
                  minWidth: '100%',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  display: 'block',
                }}
              />
            </a>
          </Grid>

          <Grid item xs={3} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
            transition: "0.2s",
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0px rgba(0, 0, 0, 0.1)',
            ":hover": {
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0px rgba(0, 0, 0, 0.3)',
              cursor: 'pointer',
            },
            p: 4,
            ml: 2,
            mr: 2,
            mb: 4,
            borderRadius: 4

          }}>
            <a
              href="https://trifork.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', textDecoration: 'none' }}
            >
              <Box
                component='img'
                src="/assets/trifork.svg"

                sx={{
                  minWidth: '100%',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  display: 'block',

                }}
              />
            </a>
          </Grid>



        </Grid>
        */}
        <Box
          sx={{
            height: "60px",
          }}
        ></Box>
      </Container>
    </Box>
  );
};

export default EventTemplate;
