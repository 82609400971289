import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import moment from "moment";

interface ResultsData {
  [team: string]: string[];
}

const formatTime = (duration: string) => {
  const momentDuration = moment.duration(duration);
  const hours = String(Math.floor(momentDuration.asHours())).padStart(2, "0");
  const minutes = String(momentDuration.minutes()).padStart(2, "0");
  const seconds = String(momentDuration.seconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

const calculateTotalTime = (times: (string | null)[]) => {
  if (times.length < 3 || times.some((time) => time === null))
    return "00:00:00";

  const totalDuration = times.reduce(
    (acc, time) => acc.add(moment.duration(time!)),
    moment.duration()
  );
  const hours = String(Math.floor(totalDuration.asHours())).padStart(2, "0");
  const minutes = String(totalDuration.minutes()).padStart(2, "0");
  const seconds = String(totalDuration.seconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

const Results: React.FC = () => {
  const [resultsData, setResultsData] = useState<ResultsData | null>(null);
  const { eventId } = useParams<{ eventId: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchResultsData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        //const response = await axios.get(`${apiUrl}1/scoreboard`);
        const response = await axios.get(
          `https://api.timing-buddy.dk/api/v1/events/4524/scoreboard`
        );
        setResultsData(response.data);
      } catch (err) {
        setError("Could not fetch results, please try again...");
      } finally {
        setLoading(false);
      }
    };

    fetchResultsData();
  }, [eventId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Box
        sx={{
          height: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          marginTop: 20,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 5,
        }}
      >
        <Typography variant="h1" fontSize={60} color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  const resultsArray = resultsData
    ? Object.entries(resultsData).map(([team, times]) => {
        const totalTime = calculateTotalTime(times);
        return { team, times, totalTime };
      })
    : [];

  resultsArray.sort((a, b) => {
    if (!a.totalTime || a.totalTime === "00:00:00") return 1;
    if (!b.totalTime || b.totalTime === "00:00:00") return -1;
    return (
      moment.duration(a.totalTime).asMilliseconds() -
      moment.duration(b.totalTime).asMilliseconds()
    );
  });

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Results
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pos</TableCell>
              <TableCell>Team</TableCell>
              <TableCell>Runde 1</TableCell>
              <TableCell>Runde 2</TableCell>
              <TableCell>Runde 3</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resultsArray.map(({ team, times, totalTime }, index) => (
              <TableRow key={team}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{team}</TableCell>
                {times.map((time, timeIndex) => (
                  <TableCell key={timeIndex}>{formatTime(time)}</TableCell>
                ))}
                <TableCell>{totalTime}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Results;
