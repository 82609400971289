import React from "react";
import {
    Box,
  Container,
  Typography,
} from "@mui/material";
import EngineeringIcon from '@mui/icons-material/Engineering';
const CommingSoon = () => {


  return (

    <Container>
      <Box
        sx={{
          height: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          marginTop: 20,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 5,
        }}
      >
        <Typography variant="h1" fontSize={50}>
          Kommer Snart...
        </Typography>
        <EngineeringIcon
          sx={{
            fontSize: 140,
            textAlign: "center",
            mt: 2,
            mb: 2,
            ml: "auto",
            mr: "auto",
          }}
        />
      </Box>      </Container>

  );
};

export default CommingSoon;
