import React from 'react';
import { AppBar, Toolbar, Box, Button, Typography, Container } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { alpha, styled } from '@mui/material/styles';

const Header = () => {

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 460,
    });

    //const StyledAppBar = styled(AppBar)(({ theme }) => ({
    //    transition: theme.transitions.create(['background-color', 'height'], {
    //        duration: theme.transitions.duration.standard,
    //        easing: theme.transitions.easing.easeInOut,
    //    }),
    //    backgroundColor: trigger ? alpha(theme.palette.primary.contrastText, 1) : 'transparent',
    //    borderBottom: trigger ? alpha(theme.palette.primary.contrastText, 1) : 'none',
    //}));

    const StyledButton = styled(Button)(({ theme }) => ({
        transition: theme.transitions.create(['color'], {}),
        color: trigger ? theme.palette.text.primary : theme.palette.common.white,
        border: 'none',
        ":hover": {
            border: 'none'
        }
    }));

    return (
        <Container maxWidth="lg">
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" >
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <a href='/'>
                            {/*<Box
                                component='img'
                                src='/assets/logo.png'
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                    height: '100%',
                                    width: '10%',
                                    objectFit: 'contain'
                                }}
                            />*/}
                            <Typography variant='h1' fontSize={35} sx={{
                                textDecoration: 'none',
                            }}>TIMING BUDDY</Typography>
                        </a>
                        {/*<Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <StyledButton variant='outlined' href="#home">Home</StyledButton>
                            <StyledButton variant='outlined' href="#about">About</StyledButton>
                            <StyledButton variant='outlined' href="#services">Services</StyledButton>
                            <StyledButton variant='outlined' href="#contact">Contact</StyledButton>
                        </Box>*/}
                    </Toolbar>
                </AppBar>
            </Box>
        </Container>
    );
};

export default Header;
