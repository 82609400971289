import React from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "300px",
        backgroundColor: "#fe8100",
        mt: 8,
        pt: 6,
      }}
    >
      <Container
        maxWidth={"xl"}
        sx={{
          height: "65%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 0 }}
          sx={{
            textAlign: "center",
          }}
        >
          <Grid item xs={4}>
            <Typography
              color={"white"}
              variant="h1"
              fontSize={"30px"}
              sx={{
                mb: 4,
              }}
            >
              KONTAKT
            </Typography>
            <Typography color={"white"} variant="body1">
              Timing Buddy
            </Typography>
            <Typography color={"white"} variant="body1">
              Strandvejen 20 4180 Sorø
            </Typography>
            <Typography color={"white"} variant="body1">
              CVR-DK: 43470582
            </Typography>
            <Typography color={"white"} variant="body1">
              info@muddy-buddy.dk
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              color={"white"}
              variant="h1"
              fontSize={"30px"}
              sx={{
                mb: 4,
              }}
            >
              NYTTIGE LINKS
            </Typography>
            <Link
              href="/"
              sx={{ color: "white", ":hover": { cursor: "pointer" } }}
              variant="body1"
            >
              Kontakt
            </Link>
            <Link
              href="/"
              sx={{ color: "white", ":hover": { cursor: "pointer" } }}
              variant="body1"
            >
              Vilkår og Betingelse
            </Link>
            <Link
              href="/"
              sx={{ color: "white", ":hover": { cursor: "pointer" } }}
              variant="body1"
            >
              Om Timing Buddy
            </Link>
            <Link
              href="/"
              sx={{ color: "white", ":hover": { cursor: "pointer" } }}
              variant="body1"
            >
              Alle Events
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Typography
              color={"white"}
              variant="h1"
              fontSize={"30px"}
              sx={{
                mb: 4,
              }}
            >
              FØLG OS
            </Typography>
            <FacebookIcon
              href="/"
              sx={{
                fontSize: "60px",
                color: "white",
                transition: "0.2s",
                ":hover": { transform: "scale(1.1)", cursor: "pointer" },
              }}
            />
            <InstagramIcon
              href="/"
              sx={{
                fontSize: "60px",
                color: "white",
                transition: "0.2s",
                ":hover": { transform: "scale(1.1)", cursor: "pointer" },
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
