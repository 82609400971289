import React from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import AssessmentIcon from "@mui/icons-material/Assessment"; // Add icon for Results

interface EventMenuProps {
  alignment: string;
  onAlignmentChange: (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => void;
}

const EventMenu: React.FC<EventMenuProps> = ({
  alignment,
  onAlignmentChange,
}) => {
  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={onAlignmentChange}
      aria-label="Platform"
      sx={{
        transform: "translate(0px, -60px)",
        transition: "0.2s ease",
        borderRadius: "0",
      }}
    >
      <ToggleButton
        value="event"
        sx={{
          backgroundColor: "#efefef1f",
          color: "primary.main",
          borderBottom: "none",
          borderTop: "solid 2px",
          borderTopColor: "#00ff0000",
          transition: "0.2s ease",
          borderRadius: "0",
          ":hover": {
            backgroundColor: "#efefef2f",
          },
          "&.Mui-selected": {
            color: "primary.main",
            borderTopColor: "green",
            borderTop: "solid 2px",
          },
        }}
      >
        <DirectionsRunIcon fontSize="small" sx={{ mr: 1 }} />
        Event
      </ToggleButton>
      <ToggleButton
        value="participants"
        sx={{
          backgroundColor: "#efefef1f",
          color: "primary.main",
          borderBottom: "none",
          borderTop: "solid 2px",
          borderTopColor: "#00ff0000",
          transition: "0.2s ease",
          ":hover": {
            backgroundColor: "#efefef2f",
          },
          "&.Mui-selected": {
            color: "primary.main",
            borderTopColor: "green",
            borderTop: "solid 2px",
          },
        }}
      >
        <PeopleAltIcon fontSize="small" sx={{ mr: 1 }} />
        Deltagere
      </ToggleButton>
      <ToggleButton
        value="results"
        sx={{
          backgroundColor: "#efefef1f",
          color: "primary.main",
          borderBottom: "none",
          borderTop: "solid 2px",
          borderTopColor: "#00ff0000",
          transition: "0.2s ease",
          borderRadius: "0",
          ":hover": {
            backgroundColor: "#efefef2f",
          },
          "&.Mui-selected": {
            color: "primary.main",
            borderTopColor: "green",
            borderTop: "solid 2px",
          },
        }}
      >
        <AssessmentIcon fontSize="small" sx={{ mr: 1 }} />
        Resultater
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default EventMenu;
