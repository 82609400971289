import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Box,
  CircularProgress,
} from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import axios from "axios";

const Participants: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { eventId } = useParams<{ eventId: string }>();
  const [participantsData, setParticipantsData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(
          `https://api.timing-buddy.dk/api/v1/events/${eventId}/registrants`
        );
        setParticipantsData(response.data);
      } catch (err) {
        setError("Kunne ikke hente deltager, prøv igen...");
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
  }, [eventId]);

  const filteredParticipants = participantsData.filter((participant) =>
    Object.values(participant).some((value) =>
      Array.isArray(value)
        ? value.some((chip) =>
            chip.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Box
        sx={{
          height: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          marginTop: 20,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 5,
        }}
      >
        <Typography variant="h1" fontSize={60} color="error">
          {error}
        </Typography>
        <SentimentVeryDissatisfiedIcon
          color="error"
          sx={{
            fontSize: 200,
            textAlign: "center",
            mt: 2,
            mb: 2,
            color: "error",
            ml: "auto",
            mr: "auto",
          }}
        />
      </Box>
    );
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Participants
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Team</TableCell>
              <TableCell>Chips</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredParticipants.map((participant) => (
              <TableRow key={participant.user_id}>
                <TableCell>{participant.team}</TableCell>
                <TableCell>{participant.chips.join(", ")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Participants;
