import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './layout/Layout.tsx';
import EventTemplate from 'pages/event/EventTemplate.tsx';
import CommingSoon from 'pages/comming-soon/page.tsx';
// const importAll = (r) => {
//   let pages = {};
//   r.keys().forEach((key) => {
//     const path = key.replace('./', '').replace('/page.tsx', '');
//     pages[path] = React.lazy(() => import(`./pages/${path}/page.tsx`));
//   });
//   return pages;
// };

// const pages = importAll(require.context('./pages', true, /\/page\.tsx$/));

// console.log('Imported Pages:', pages);

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* {Object.keys(pages).map((path) => {
            const routePath = path === 'homepage' ? '' : path;
            console.log('Setting up route:', routePath);

            return (
              <Route
                key={path}
                path={routePath}
                element={
                  <React.Suspense fallback={<div>Loading...</div>}>
                    {React.createElement(pages[path])}
                  </React.Suspense>
                }
              />
            );
          })} */}
          <Route path="/event/:eventId" element={<EventTemplate />} />
          <Route
            path="/"
            element={<CommingSoon/>}
          />
          <Route
            path="*"
            element={<CommingSoon/>}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
