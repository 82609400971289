import React, { useState } from "react";
import { Typography, Card, CardContent } from "@mui/material";
import ReactMarkdown from 'react-markdown';
import EventMenu from "components/eventTemplate/EventMenu";
import Results from "components/eventTemplate/Results";
import Participants from "components/eventTemplate/Participants";

interface EventInfoProps {
    description: string;
    eventName: string;
}

const EventInfo: React.FC<EventInfoProps> = ({ description, eventName }) => {
    const [alignment, setAlignment] = useState('event');

    const markdown = description;

    const handleEventMenuChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    return (
        <Card sx={{ margin: 2, overflow: 'visible' }}>
            <EventMenu alignment={alignment} onAlignmentChange={handleEventMenuChange} />
            <CardContent sx={{ mt: -6 }}>
                {alignment === 'event' && (
                    <>
                        <Typography variant="h4">{eventName}</Typography>
                        <ReactMarkdown>{markdown}</ReactMarkdown>
                    </>
                )}
                {alignment === 'participants' && (
                    <Participants />
                )}
                {alignment === 'results' && (
                    <Results />
                )}
            </CardContent>
        </Card>
    );
};


export default EventInfo;
