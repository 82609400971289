import React from 'react';
import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';


const Layout = () => {
    return (
        <div>
            <Header />
            <Container
                disableGutters
                maxWidth={false}
                sx={{ height: '100%', padding: 0, margin: 0 }}
            >
                <Outlet />
            </Container>
            <Footer />
        </div>
    );
};

export default Layout;
